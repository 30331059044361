// Типы действий для управления количеством продуктов
export const INCREMENT_VARIANT_QUANTITY = 'INCREMENT_VARIANT_QUANTITY'
export const INCREMENT_VARIANT_QUANTITY_FROM_CARD = 'INCREMENT_VARIANT_QUANTITY_FROM_CARD'
export const DECREMENT_VARIANT_QUANTITY = 'DECREMENT_VARIANT_QUANTITY'
export const DECREMENT_VARIANT_QUANTITY_FROM_CARD = 'DECREMENT_VARIANT_QUANTITY_FROM_CARD'

// Типы действий для модальных окон
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

// Типы действий для корзины
export const ADD_PRODUCT_TO_BASKET = 'ADD_PRODUCT_TO_BASKET'
export const REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET'

// Типы действий для очистки данных
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT'
export const CLEAR_PRODUCT_VARIANTS_LIST = 'CLEAR_PRODUCT_VARIANT_LIST'
export const CLEAR_AUTH_REQUEST = 'CLEAR_AUTH_REQUEST'
export const CLEAR_AUTH_PHONE = 'CLEAR_AUTH_PHONE'
export const CLEAR_AUTH = 'CLEAR_AUTH'
export const CLEAR_PROMOCODE = 'CLEAR_PROMOCODE'
export const CLEAR_LOAD_ADDRESSES = 'CLEAR_LOAD_ADDRESSES'
export const CLEAR_ADDRESSES = 'CLEAR_ADDRESSES'
export const CLEAR_DELIVERY_DETAILS = 'CLEAR_DELIVERY_DETAILS'
export const CLEAR_ORDER_DETAILS_ADDRESS = 'CLEAR_ORDER_DETAILS_ADDRESS'
export const CLEAR_ORDER_DETAILS_PICKUP = 'CLEAR_ORDER_DETAILS_PICKUP'
export const CLEAR_PRODUCTS_AND_DRAFT_PRODUCTS = 'CLEAR_PRODUCTS_AND_DRAFT_PRODUCTS'
export const CLEAR_CUSTOMER_ANSWERS = 'CLEAR_CUSTOMER_ANSWERS'
export const CLEAR_BASKET = 'CLEAR_BASKET'
export const CLEAR_FEEDBACK_FORM = 'CLEAR_FEEDBACK_FORM'
export const CLEAR_ORDER_DETAILS = 'CLEAR_ORDER_DETAILS'
export const CLEAR_DRAFT_PRODUCT = 'CLEAR_DRAFT_PRODUCT'

// Типы действий для обновления изображений
export const ACTIVATE_NEXT_IMAGE = 'ACTIVATE_NEXT_IMAGE'
export const ACTIVATE_PREV_IMAGE = 'ACTIVATE_PREV_IMAGE'
export const RESET_ACTIVE_IMAGE = 'RESET_ACTIVE_IMAGE'
export const RESET_IMAGE_POSITION = 'RESET_IMAGE_POSITION'
export const SET_ACTIVE_IMAGE = 'SET_ACTIVE_IMAGE'
export const SET_CROPPED_IMAGE = 'SET_CROPPED_IMAGE'
export const SET_CROPPED_IMAGE_DATA = 'SET_CROPPED_IMAGE_DATA'
export const SET_CROPPED_IMAGE_ORIGIN = 'SET_CROPPED_IMAGE_ORIGIN'
export const SET_IMAGE_PATH = 'SET_IMAGE_PATH'
export const SET_MAIN_IMAGE = 'SET_MAIN_IMAGE'
export const UPDATE_CROPPED_IMAGE = 'UPDATE_CROPPED_IMAGE'
export const UPDATE_CROPPED_IMAGE_DATA = 'UPDATE_CROPPED_IMAGE_DATA'
export const UPDATE_CROPPED_IMAGE_ORIGIN = 'UPDATE_CROPPED_IMAGE_ORIGIN'

// Типы действий для слайдов
export const SET_SLIDE_LINK_OPTIONS = 'SET_SLIDE_LINK_OPTIONS'
export const SET_SLIDES = 'SET_SLIDES'
export const SET_SLIDE_PREVIEW = 'SET_SLIDE_PREVIEW'

// Типы действий для формы обратной связи
export const UPDATE_FEEDBACK_NAME = 'UPDATE_FEEDBACK_NAME'
export const UPDATE_FEEDBACK_EMAIL = 'UPDATE_FEEDBACK_EMAIL'
export const UPDATE_FEEDBACK_PHONE = 'UPDATE_FEEDBACK_PHONE'
export const UPDATE_FEEDBACK_MESSAGE = 'UPDATE_FEEDBACK_MESSAGE'

// Типы действий для вопросов
export const SET_QUESTIONS = 'SET_QUESTIONS'

// Типы действий для аутентификации
export const UPDATE_AUTH_PHONE = 'UPDATE_AUTH_PHONE'
export const UPDATE_AUTH_CODE = 'UPDATE_AUTH_CODE'
export const SET_AUTH_PHONE_REQUEST = 'SET_AUTH_PHONE_REQUEST'
export const SET_CUSTOM_AUTH_PHONE_ERROR = 'SET_CUSTOM_AUTH_PHONE_ERROR'
export const SET_AUTH_DATA = 'SET_AUTH_REQUEST'

// Типы действий для специальных функций
export const SET_IS_USING_SHOP_LOGIC = 'SET_IS_USING_SHOP_LOGIC'
export const UPDATE_HTTP_IN_PROGRESS = 'UPDATE_HTTP_IN_PROGRESS'
export const SET_SCREEN_WIDTH = 'SET_SCREEN_WIDTH'

// Типы действий для доставки
export const UPDATE_DELIVERY_TYPE = 'UPDATE_DELIVERY_TYPE'
export const SET_DELIVERY_INTERVALS = 'SET_DELIVERY_INTERVALS'

// Типы действий для доставки курьером
export const UPDATE_DELIVERY_ADDRESS_FULL_STREET = 'UPDATE_DELIVERY_ADDRESS_FULL_STREET'
export const SET_LOADER_ADDRESSES = 'SET_LOADER_ADDRESSES'
export const SET_LOADED_ADDRESS_IN_ADDRESS = 'SET_LOADED_ADDRESS_IN_ADDRESS'

// Типы действий для доставки самовывоза
export const SET_PICKUP_POINTS = 'SET_PICKUP_POINTS'
export const UPDATE_PICKUP_POINTS_SELECTED = 'UPDATE_PICKUP_POINTS_SELECTED'

// Типа действия для цены доставки
export const SET_DELIVERY_PRICES = 'SET_DELIVERY_PRICES'
export const UPDATE_DELIVERY_DETAILS_MESSAGE = 'UPDATE_DELIVERY_DETAILS_MESSAGE'
export const UPDATE_DELIVERY_DETAILS_THRESHOLD_DELIVERY_PRICE = 'UPDATE_DELIVERY_DETAILS_THRESHOLD_DELIVERY_PRICE'
export const UPDATE_DELIVERY_DETAILS_REMAINING_AMOUNT_DELIVERY_PRICE = 'UPDATE_DELIVERY_DETAILS_REMAINING_AMOUNT_DELIVERY_PRICE'

// Типы действий для обновления информации о клиенте
export const UPDATE_CUSTOMER_CITY = 'UPDATE_CUSTOMER_CITY'
export const UPDATE_CUSTOMER_NAME = 'UPDATE_CUSTOMER_NAME'
export const UPDATE_CUSTOMER_PHONE = 'UPDATE_CUSTOMER_PHONE'
export const SET_PAID_BY_BONUSES_IN_CUSTOMER = 'SET_PAID_BY_BONUSES_IN_CUSTOMER'
export const UPDATE_BUYER_BONUSES = 'UPDATE_BUYER_BONUSES'

// Типы действий для обновления информации о заказе
export const UPDATE_ORDER_DETAILS_PAYMENT_TYPE = 'UPDATE_ORDER_DETAILS_PAYMENT_TYPE'
export const UPDATE_ORDER_DETAILS_DELIVERY_TYPE = 'UPDATE_ORDER_DETAILS_DELIVERY_TYPE'
export const UPDATE_ORDER_DETAILS_ALLOW_PERSONAL_DATA_PROCESSING = 'UPDATE_ORDER_DETAILS_ALLOW_PERSONAL_DATA_PROCESSING'
export const UPDATE_ORDER_DETAILS_DELIVERY_DATE = 'UPDATE_ORDER_DETAILS_DELIVERY_DATE'
export const UPDATE_ORDER_DETAILS_DELIVERY_INTERVAL = 'UPDATE_ORDER_DETAILS_DELIVERY_INTERVAL'
export const UPDATE_ORDER_DETAILS_DELIVERY_PRICE = 'UPDATE_ORDER_DETAILS_DELIVERY_PRICE'
export const UPDATE_ORDER_DETAILS_ADDRESS_APARTMENT = 'UPDATE_ORDER_DETAILS_ADDRESS_APARTMENT'
export const UPDATE_ORDER_DETAILS_ADDRESS_CITY = 'UPDATE_ORDER_DETAILS_ADDRESS_CITY'
export const UPDATE_ORDER_DETAILS_ADDRESS_DOOR_CODE = 'UPDATE_ORDER_DETAILS_ADDRESS_DOOR_CODE'
export const UPDATE_ORDER_DETAILS_ADDRESS_ENTRANCE = 'UPDATE_ORDER_DETAILS_ADDRESS_ENTRANCE'
export const UPDATE_ORDER_DETAILS_ADDRESS_FLOOR = 'UPDATE_ORDER_DETAILS_ADDRESS_FLOOR'
export const UPDATE_ORDER_DETAILS_ADDRESS_FULL_STREET = 'UPDATE_ORDER_DETAILS_ADDRESS_FULL_STREET'
export const UPDATE_ORDER_DETAILS_ADDRESS_STREET = 'UPDATE_ORDER_DETAILS_ADDRESS_STREET'
export const UPDATE_ORDER_DETAILS_ADDRESS_HOUSE = 'UPDATE_ORDER_DETAILS_ADDRESS_HOUSE'
export const UPDATE_ORDER_DETAILS_CHOOSE_ADDRESS = 'UPDATE_ORDER_DETAILS_CHOOSE_ADDRESS'
export const UPDATE_ORDER_DETAILS_PICKUP_POINT = 'UPDATE_ORDER_DETAILS_PICKUP_POINT'

// Типы действий для бонусной системы
export const UPDATE_PAID_BY_BONUSES_LOCAL = 'UPDATE_PAID_BY_BONUSES_LOCAL'
export const UPDATE_PROMOCODE = 'UPDATE_PROMOCODE'
export const SET_BONUS_SYSTEM = 'SET_BONUS_SYSTEM'
export const SET_PROMOCODE = 'SET_PROMOCODE'
export const SET_ERROR_PROMOCODE = 'SET_ERROR_PROMOCODE'

// Действия для управления вариантами продукта
export const SET_CURRENT_VARIANTS = 'SET_CURRENT_VARIANTS'
export const SET_PARAMETERS_LENGTH = 'SET_PARAMETERS_LENGTH'
export const SET_PARAMETERS_VARIANTS = 'SET_PARAMETERS_VARIANTS'
export const SET_OPTIONS_VARIANTS = 'SET_OPTIONS_VARIANTS'
// TODO: Не используется, посмотреть почему
export const SET_VARIANTS = 'SET_VARIANTS'
export const RESET_PRODUCT_PARAMETERS = 'RESET_PRODUCT_PARAMETERS'

// Действия для управления модификаторами
export const SELECT_MODIFIER = 'SELECT_MODIFIER'
export const SELECT_GROUP_MODIFIER = 'SELECT_GROUP_MODIFIER'
export const SET_MODIFIERS = 'SET_MODIFIERS'
export const RESET_ACTIVE_PRICE_VARIANT = 'RESET_ACTIVE_PRICE_VARIANT'
export const RESET_SELECTED_MODIFIERS = 'RESET_SELECTED_MODIFIERS'

// Действия для управления фильтрами
export const SET_PRICE_RANGE = 'SET_PRICE_RANGE'
export const UPDATE_FILTER_FROM_PRICE = 'UPDATE_FILTER_FROM_PRICE'
export const UPDATE_FILTER_TO_PRICE = 'UPDATE_FILTER_TO_PRICE'
export const UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS'
export const TOGGLE_FILTER_OPTION = 'TOGGLE_FILTER_OPTION'

// Действия для управления данными о продукте
export const FILL_INIT_PRODUCTS = 'FILL_INIT_PRODUCTS'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_AMOUNT = 'SET_AMOUNT'

// Действия для управления городами и контактами
export const SET_CITIES = 'SET_CITIES'
export const SET_CONTACTS = 'SET_CONTACTS'

// Действия для управления параметрами
export const CHANGE_TOGGLE_PARAMETER = 'CHANGE_TOGGLE_PARAMETER'
export const SET_ACTIVE_TOGGLE = 'SET_ACTIVE_TOGGLE'
export const RESET_ACTIVE_TOGGLE = 'RESET_ACTIVE_TOGGLE'

// Действия для управления draft
// TODO: Разобраться где они используются
export const CHANGE_DRAFT_PRODUCT_QUANTITY = 'CHANGE_DRAFT_PRODUCT_QUANTITY'
export const CHANGE_DRAFT_DISCRIMINATED_QUANTITY = 'CHANGE_DRAFT_DISCRIMINATED_QUANTITY'
export const CHANGE_DRAFT_PRODUCT_PARAMETER = 'CHANGE_DRAFT_PRODUCT_PARAMETER'
export const CHANGE_DRAFT_DISCRIMINATED_PARAMETER = 'CHANGE_DRAFT_DISCRIMINATED_PARAMETER'
export const SET_DRAFT = 'SET_DRAFT'

// Действия для телеграм пользователя
export const SET_TELEGRAM_USER = 'SET_TELEGRAM_USER'

// Действия для цен в разных точках
export const SET_PRICES_BY_PICKUP_POINTS = 'SET_PRICES_BY_PICKUP_POINTS'
export const UPDATE_PRICES_IN_BASKET = 'UPDATE_PRICES_IN_BASKET'
