import { get, getById, post } from './requests'

export const fetchOptionsFilters = () => get('filters/options')
export const fetchPriceRange = () => get('filters/priceRange')
export const fetchQuestions = () => get('questions')
export const fetchCities = () => get('cities')
export const fetchBonusSystem = () => get('bonus-system')
export const fetchShopContacts = () => get('shop-contacts')
export const fetchDeliveryIntervals = () => get('deliveryIntervals')
export const fetchPickupPoints = () => get('pickup-points')
export const fetchPromoCode = name => get(`promo-code/${name}`)
export const fetchProducts = () => get('products')
export const fetchPricesByPickupPoints = () => get('availability')

export const fetchVariants = productId => getById('variants', productId)
export const fetchParameters = productId => getById('parameters', productId)
export const fetchCurrentBuyerBonuses = () => get('buyer/bonuses')

export const calculateVariants = data => post('variants/calculate', data)
export const createOrder = data => post('orders/create', data)
export const createAuthRequest = data => get(`request-auth-code/${data}`)
export const submitAuth = (phone, code) => get(`check-code/${phone}/${code}`)
export const fetchDeliveryPrice = data => post('deliveryPrice', data)

export const getDefaultCity = () => get('defaultCity')
export const getLastUpdate = () => get('last-update')
export const telegramAuth = data => post('telegram-auth', data)
