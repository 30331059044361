export const LOAD_OPTIONS_FILTERS = 'LOAD_OPTIONS_FILTERS'
export const LOAD_PRICE_RANGE = 'LOAD_PRICE_RANGE'
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS'
export const LOAD_CITIES = 'LOAD_CITIES'
export const LOAD_BONUS_SYSTEM = 'LOAD_BONUS_SYSTEM'
export const LOAD_SHOP_CONTACTS = 'LOAD_SHOP_CONTACTS'
export const LOAD_DELIVERY_PRICES = 'LOAD_DELIVERY_PRICES'
export const LOAD_DELIVERY_INTERVALS = 'LOAD_DELIVERY_INTERVALS'
export const LOAD_PICKUP_POINTS = 'LOAD_PICKUP_POINTS'
export const LOAD_PROMOCODE = 'LOAD_PROMOCODE'
export const LOAD_STREET_DA_DATA = 'LOAD_STREET_DA_DATA'
export const LOAD_ALL_PRODUCTS = 'LOAD_ALL_PRODUCTS'
export const LOAD_VARIANTS = 'LOAD_VARIANTS'
export const LOAD_BUYER_BONUSES = 'LOAD_BUYER_BONUSES'

export const CHOOSE_ADDRESS_COURIER = 'CHOOSE_ADDRESS_COURIER'
export const CHOOSE_ADDRESS_PICKUP = 'CHOOSE_ADDRESS_PICKUP'
export const CHOOSE_PICKUP_POINT_ON_MAP = 'CHOOSE_PICKUP_POINT_ON_MAP'
export const CHOOSE_DELIVERY_TYPE = 'CHOOSE_DELIVERY_TYPE'

export const ADD_TO_BASKET = 'ADD_TO_BASKET'

export const CREATE_ORDER = 'CREATE_ORDER'
export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const REQUEST_AUTH_CODE = 'REQUEST_AUTH_CODE'
export const CHECK_CODE = 'SEND_AUTH_CODE'
export const CLEAR_PRODUCTS_ON_CATALOG_UPDATE = 'CLEAR_PRODUCTS_ON_CATALOG_UPDATE'
export const CHANGE_DRAFT_QUANTITY = 'CHANGE_DRAFT_QUANTITY'
export const CHANGE_DISCRIMINATED_QUANTITY = 'CHANGE_DISCRIMINATED_QUANTITY'

export const LOGOUT_USER = 'LOGOUT_USER'

export const UPDATE_TELEGRAM_USER = 'UPDATE_TELEGRAM_USER'

export const SET_PRICES_BY_PICKUP_POINTS = 'SET_PRICES_BY_PICKUP_POINTS'
