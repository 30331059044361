var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "init-modal",
    {
      staticClass: "product-modal-fullscreen",
      attrs: { "is-show": _vm.isShow },
      on: { close: _vm.closeModal }
    },
    [
      _c(
        "div",
        { staticClass: "product-modal-fullscreen__content" },
        [
          _vm.productImages.length > 0
            ? _c("app-product-slider", {
                attrs: {
                  images: _vm.productImages,
                  "need-reset-component": _vm.isShow
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-modal-fullscreen__body" },
            [
              _c(
                "div",
                { staticClass: "product-modal-fullscreen__body-product-desc" },
                [
                  _c(
                    "h2",
                    { staticClass: "product-modal-fullscreen__body-title" },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.modalTitle) + "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "product-modal-fullscreen__body-desc" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.productBody || _vm.productDescription) +
                          "\n        "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.productVariantsListLength > 0 && _vm.multipleOptionsList > 1
                ? _c("div", { staticClass: "variants-list" }, [
                    _c("p", { staticClass: "variants-list__title" }, [
                      _vm._v("Выберите размер")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "variants-list__container" },
                      _vm._l(_vm.productVariants, function(v, index) {
                        return _c(
                          "button",
                          {
                            key: index,
                            staticClass: "variants-list__btn",
                            class: {
                              "variants-list__btn--active":
                                _vm.activeVariant === index
                            },
                            on: {
                              click: function($event) {
                                return _vm.activateToggleProduct(
                                  index,
                                  v.parameters[0].key,
                                  _vm.productId
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "variants-list__btn-title" },
                              [_vm._v(_vm._s(v.title))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "variants-list__btn-wrapper" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "variants-list__btn-weight" },
                                  [_vm._v(_vm._s(v.weight) + "г")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "variants-list__btn-price" },
                                  [_vm._v(_vm._s(_vm.getVariantPrice(v)) + "₽")]
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGroupModifiers
                ? _c("p", { staticClass: "variants-list__title" }, [
                    _vm._v("Добавки")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(
                _vm.productVariants[_vm.activePriceIndex].groupModifiers,
                function(groupModifier, index) {
                  return _vm.productBuyable
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass: "product-modal-fullscreen__additives"
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "groupModifier-list__title" },
                            [_vm._v(_vm._s(groupModifier.title))]
                          ),
                          _vm._v(" "),
                          groupModifier.maxAmount === 1
                            ? _c(
                                "div",
                                { staticClass: "modifier-list__container" },
                                _vm._l(groupModifier.modifiers, function(
                                  modifier,
                                  modifierIndex
                                ) {
                                  return _c(
                                    "button",
                                    {
                                      key: modifierIndex,
                                      staticClass: "modifier-list__btn",
                                      class: {
                                        "modifier-list__btn--active":
                                          _vm.radioModifiersIdList[
                                            groupModifier.title
                                          ] === modifier.id
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectRadioModifiersId(
                                            modifier.id,
                                            groupModifier.title
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "modifier-list__btn-title"
                                        },
                                        [_vm._v(_vm._s(modifier.title))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "modifier-list__btn-price"
                                        },
                                        [
                                          _vm._v(
                                            "+" + _vm._s(modifier.price) + "₽"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          groupModifier.maxAmount > 1
                            ? _c(
                                "div",
                                { staticClass: "modifier-list__container" },
                                _vm._l(groupModifier.modifiers, function(
                                  modifier,
                                  modifierIndex
                                ) {
                                  return _c(
                                    "button",
                                    {
                                      key: modifierIndex,
                                      staticClass: "modifier-list__btn",
                                      class: {
                                        "modifier-list__btn--active": _vm.checkboxModifiersIdList.includes(
                                          modifier.id
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectCheckboxModifiersId(
                                            modifier.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "modifier-list__btn-title"
                                        },
                                        [_vm._v(_vm._s(modifier.title))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "modifier-list__btn-price"
                                        },
                                        [
                                          _vm._v(
                                            "+" + _vm._s(modifier.price) + "₽"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                }
              ),
              _vm._v(" "),
              _vm.isUsingShopLogic
                ? _c(
                    "div",
                    {
                      staticClass: "product-modal-fullscreen__button",
                      style: { bottom: _vm.totalSum > 0 ? "70px" : "10px" }
                    },
                    [
                      _vm.productBuyable && _vm.amountProduct !== 0
                        ? _c(
                            "app-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.addToBasket(_vm.productId)
                                }
                              }
                            },
                            [
                              _vm._v("\n          Положить в корзину "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "product-modal-fullscreen__button-text"
                                },
                                [_vm._v(_vm._s(_vm.resultSum) + "₽")]
                              )
                            ]
                          )
                        : _c("app-button", { attrs: { "is-disabled": "" } }, [
                            _vm._v("\n          Нет в наличии\n        ")
                          ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4b74769e", { render: render, staticRenderFns: staticRenderFns })
  }
}